.container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.input{
    width: 40%;
    border-radius: 8px;
    border: 2px solid #0b5ed7;
    padding: 10px 15px;
    /* box-shadow: 1px 3px 9px #0b5ed7 ; */
    
}

.input:focus{
    outline: none;
}


@media(max-width: 576px){
    .input{
        width: 80%;
    }
}