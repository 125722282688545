.container3{
  max-width: 100%;
          overflow-x: hidden;
        
}

.getstartedbutton{
    display: flex;
    justify-content: center;
    padding-top: 12rem;
    gap: 5rem;
   
}

.testbutton{
  display: flex;
  justify-content: center;
  align-items: center;
}

.buton6{
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: inherit;
  margin-top: 10px;
  background-color: #319795;
  color: white;
  border: none;
  height: 50px;
  width: 220px;
  border-radius: 15px;
}
  

.repository{
    position: absolute;
    top: 13rem;
    left: 50rem;
}

.rickandmortyimage{
    width: 105rem;
    height: 45rem;
    
}


.randomtext{
    display: flex;
    justify-content: center;
    
   
    font-size: 2rem;
    color: rgb(49, 151, 149);
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.rickandmorty{
    height: 20rem;
    margin-right: 4rem;
    margin-left: 10rem;

}

.rickandmorty1{
  height: 20rem;
    margin-right: 4rem;
    margin-left: 10rem;
}

h4{
    font-size: 1rem;
    padding-right: 190px;
}

.random{
    display: flex;
    justify-content: center;
    align-items: center;
    
}


.test2{
    margin-top: 5rem;
   align-items: center;
   text-align: center;
   margin-bottom: 5rem;
}


.header_container {
    position: relative;
    overflow: hidden;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url('https://wallpaper.dog/large/17058391.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100vw;
    height: 100vh;
  }
   .header_img{
  
     /* height: 400px; */
    /* // position: relative; */
    /* // overflow: hidden; */
    text-align: center;
    color: #fff;
    /* // font-size: 140%; */
    word-spacing: 4px;
    letter-spacing: 1px;
    top: 5vh;
    /* left: 33%; */
    position: absolute;
    width: 100%;
    letter-spacing: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  } 
  
  .btn-show-more-1 {
    background-color: #e67e22;
    border: 1px solid #e67e22;
    text-decoration: none;
    font-weight: 500;
    display: inline-block;
    text-align: center;
    padding: 10px 10px;
    color: #fff;
    margin-right: 30px;
    width: 12vw;
    font-family: 'Exo 2', sans-serif;
    font-size: 1.2rem;
    border-radius: 30px;
  }
  .header-1 {
    font-family: 'Ubuntu';
    font-size: 3rem;
    margin-bottom: 2px;
    font-family: 'Urbanist', sans-serif;
    font-weight: 600;
    margin: 2;
    padding: 0;
  }
  .header-2 {
    
    font-size: 3rem;
    margin-bottom: 20px;
    width: fit-content;
    font-family: 'Urbanist', sans-serif;
    font-weight:600;
    margin: 2;
    padding: 0;
  }

  /* End of the header */

  .buton3{
    margin-top: 3rem;
  }

  .buton6{
    
  
    text-transform:inherit;
    margin-top: 10px;
    background-color: #319795;
    color: white;
    border: none;
    height: 50px;
    width: 220px;
    border-radius: 15px;
  }


  .images{
   display: grid;
   grid-template-columns: repeat(3,1fr);
   margin-left: 40px;
    column-gap: 5px;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

  .image22{
    height: 80%;
    width: 91%;
  }


  /* Top cast */


.wrapper{ 
  display: grid;
  grid-template-columns: repeat(3,1fr);
  column-gap: 5rem;
  width: 1170px;
  margin: auto;
}

 
.figure{
  width: 32%;
  float: left;
  margin: 0;
}

.hover-animation{
  position: relative;
  height: 350px;
  width: 350px;
  margin: 10% auto 0;
  color: #fff;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.6);
}

.hover-animation img{
  position: absolute;
  height: 350px;
  width: 350px;
  left: 0;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;

}

.hover-animation img.img-front:hover{
opacity: 0;
cursor: pointer;
}

 

/* Youtube src */

.containervideosrc{
  /* padding-left: 10rem; */
  display: grid;
  grid-template-columns: repeat(2,1fr);
  align-items: center;  
  justify-content: center;
  justify-items: center;
}

.youtubetext{
  padding-right: 100px;
}

.youtubetexting{
  display: flex;
  justify-content: center;
  margin-bottom: 5rem;
  font-size: 2rem;
}

 
  
  @media (max-width: 1275px) {
    .header_img {
        
      flex-direction: column;
      left: 0;
      top: 40%;
      width: 100%;
      margin: 0 auto;
      align-items: center;
      justify-content: center;
    }
    .header-1,
    .header-2 {
      font-size: 2rem;
    }
    .btn-show-more-1 {
      padding: 10px 30px;
    }
    
    .wrapper {
      display: grid;
      grid-template-columns: repeat(2,1fr);
      column-gap: 2rem;
      width: 720px;
      margin: auto;
  }

  .images {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    margin-left: 40px;
    column-gap: 5px;
    margin-bottom: 2rem;
    margin-top: 2rem;
}
.header_img {
  flex-direction: column;
  left: 0;
  top: 25%;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}

.containervideosrc {
  /* padding-left: 10rem; */
  display: grid;
  grid-template-columns: repeat(1,1fr);
  align-items: center;
  justify-content: center;
  justify-items: center;
  gap: 20px;
}

.youtubetext{
  font-size: 1.5rem;
  padding-left: 4rem;
}

.randomtext{
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 2rem;
  color: rgb(49, 151, 149);
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.random1text{
  margin-right: -2rem;
  margin-left: 10rem;
  text-align: center;
  /* margin-top: 2rem; */
  font-size: 1.2rem;
  margin-bottom: 5rem;
}

.random{
  display: grid;
  gap: 2rem;
}


  }





 /* Mobile responsive */




  
  @media (max-width: 767px) {

  .container3{
    max-width: 100%;
            overflow-x: hidden;
          
  }

    .header_img {
      flex-direction: column;
      left: 0;
      top: 7%;
      width: 100%;
      margin: 0 auto;
      align-items: center;
      justify-content: center;
    }
    .header-1,
    .header-2 {
      font-size: 1.5rem;
    }
    .btn-show-more-1 {
      padding: 10px 30px;
      width: 50vw;
    }

    .repository {
        position: absolute;
        top: 42rem;
        left: 12rem;
    }

    .getstartedbutton {
      
        /* position: absolute; */
        top: 40rem;
        left: 0rem;
      
        
    }

    .getstartedbutton {
      display: flex;
      justify-content: center;
      padding-top: 10rem;
      gap: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
  }

    .wrapper {
      display: grid;
     
      grid-template-columns: repeat(1,1fr);
      column-gap: 5rem;
      margin: auto;
  }
     
  .hover-animation {
    position: relative;
    height: 350px;
    width: 350px;
    margin:  auto 0;
    color: #fff;
    box-shadow: 5px 5px 15px rgb(0 0 0 / 60%);
}
     
  .figurecaption{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .test2 {
    margin-top: 10rem;
    align-items: center;
    /* text-align: center; */
    margin-bottom: 10rem;
}
.image22 {
  height: 90%;
  width: 91%;
}

.images{
  display: grid;
  grid-template-columns: repeat(1,1fr);
  margin-left: 40px;
   column-gap: 5px;
   margin-bottom: 2rem;
   margin-top: 3rem;
 }

 .containervideosrc {
  display: grid;
  grid-template-columns: repeat(1,1fr);
  align-items: center;
  justify-content: center;
  justify-items: center;
}

.youtubetext{
  margin-top: 2rem;
  font-size: 0.8rem;
padding-left: 3rem;
padding-right: 2rem;
}

.video{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 2rem;
  padding-right: 2rem;
}

.randomtext{

  text-align: center;
  font-size: 1rem;
}

.random {
  display: grid;
  grid-template-rows: repeat(1,1fr);
  justify-content: center;
  align-items: center;
}

.rickandmorty {
  padding-left: rem;
  padding-right: 6rem;
  height: 12rem;
  /* margin-right: 4rem;
  margin-left: 10rem; */
}

.random1text{
  margin-right: -2rem;
  margin-left: 10rem;
  text-align: center;
  /* margin-top: 2rem; */
  font-size: 0.8rem;
  margin-bottom: 5rem;
 
}



figure {

  
  /* margin-right: 1rem;
  margin-left: 1rem; */
}

.test2 {
  margin-top: 3rem;
  align-items: center;
  
  margin-bottom: 3rem;
}

figure {
align-items: center;
justify-content: center;
align-self: center;
padding-left: 7px;

}

.figtext{
      text-align: left;
}


.test13{
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  padding-left: 2rem;
  padding-right: 2.5rem;
}



  }
      

 