.cards{
    /* border: 2px solid #0b5ed7; */
    border-radius: 10px;
    margin-bottom: 20px;

}



.content{
    padding: 10px;
}