.randomtext3{
 
        display: flex;
        justify-content: center;
        
       
        font-size: 2rem;
        color: rgb(49, 151, 149);
        /* margin-top: 5rem;
        margin-bottom: 5rem; */
    }
    

